.main-app-bg.zoom-20 {
    width: 120%;
}
.main-app-bg.zoom-40 {
    width: 140%;
}
.main-app-bg.zoom-60 {
    width: 160%;
}
.main-app-bg.zoom-80 {
    width: 180%;
}
.main-app-bg.zoom-100 {
    width: 200%;
}

.App.zoom-20 {
    /*width: 120%;*/
    width: 1536px!important;
    max-width: 1536px!important;
}
.App.zoom-40 {
    /*width: 140%;*/
    width: 1792px!important;
    max-width: 1792px!important;
}
.App.zoom-60 {
    /*width: 160%;*/
    width: 2048px!important;
    max-width: 2048px!important;
}
.App.zoom-80 {
    /*width: 180%;*/
    width: 2304px!important;
    max-width: 2304px!important;
}
.App.zoom-100 {
    /*width: 200%;*/
    width: 2560px!important;
    max-width: 2560px!important;
}

/************    LEFT BLOCK   *************/
.zoom-20 .Vertical-droppable-field {
    height: 96px;
}
.zoom-40 .Vertical-droppable-field {
    height: 112px;
}
.zoom-60 .Vertical-droppable-field {
    height: 128px;
}
.zoom-80 .Vertical-droppable-field {
    height: 144px;
}
.zoom-100 .Vertical-droppable-field {
    height: 160px;
}

.zoom-20 .Left-block .Text-bold-size,
.zoom-20 .Right-block .Text-bold-size {
    font-size: 5pt;
}
.zoom-40 .Left-block .Text-bold-size,
.zoom-40 .Right-block .Text-bold-size {
    font-size: 6pt;
}
.zoom-60 .Left-block .Text-bold-size,
.zoom-60 .Right-block .Text-bold-size {
    font-size: 7pt;
}
.zoom-80 .Left-block .Text-bold-size,
.zoom-80 .Right-block .Text-bold-size {
    font-size: 8pt;
}
.zoom-100 .Left-block .Text-bold-size,
.zoom-100 .Right-block .Text-bold-size {
    font-size: 9pt;
}

.zoom-20 .Left-block .Text-size,
.zoom-20 .Right-block .Text-size {
    font-size: 4.5pt;
}
.zoom-40 .Left-block .Text-size,
.zoom-40 .Right-block .Text-size {
    font-size: 5.5pt;
}
.zoom-60 .Left-block .Text-size,
.zoom-60 .Right-block .Text-size {
    font-size: 6.5pt;
}
.zoom-80 .Left-block .Text-size,
.zoom-80 .Right-block .Text-size {
    font-size: 7.5pt;
}
.zoom-100 .Left-block .Text-size,
.zoom-100 .Right-block .Text-size {
    font-size: 8.5pt;
}

/******************************************/

/************* Finance Block **************/

.zoom-20 .Finance-droppable-field {
    height: 48px;
}
.zoom-40 .Finance-droppable-field {
    height: 56px;
}
.zoom-60 .Finance-droppable-field {
    height: 64px;
}
.zoom-80 .Finance-droppable-field {
    height: 72px;
}
.zoom-100 .Finance-droppable-field {
    height: 80px;
}

.zoom-20 .Token-droppable-field {
    height: 57.6px;
}
.zoom-40 .Token-droppable-field {
    height: 67.2px;
}
.zoom-60 .Token-droppable-field {
    height: 76.8px;
}
.zoom-80 .Token-droppable-field {
    height: 86.4px;
}
.zoom-100 .Token-droppable-field {
    height: 96px;
}

.zoom-20 .Finance-bold-title-text-size,
.zoom-20 .Finance-title-text-size {
    font-size: 11pt;
}
.zoom-40 .Finance-bold-title-text-size,
.zoom-40 .Finance-title-text-size {
    font-size: 12pt;
}
.zoom-60 .Finance-bold-title-text-size,
.zoom-60 .Finance-title-text-size {
    font-size: 13pt;
}
.zoom-80 .Finance-bold-title-text-size,
.zoom-80 .Finance-title-text-size {
    font-size: 14pt;
}
.zoom-100 .Finance-bold-title-text-size,
.zoom-100 .Finance-title-text-size {
    font-size: 15pt;
}

.zoom-20 .Finance-text-size {
    font-size: 7pt;
}
.zoom-40 .Finance-text-size {
    font-size: 8pt;
}
.zoom-60 .Finance-text-size {
    font-size: 9pt;
}
.zoom-80 .Finance-text-size {
    font-size: 10pt;
}
.zoom-100 .Finance-text-size {
    font-size: 11pt;
}

.zoom-20 .Finance-cash-text-size {
    font-size: 6pt;
}
.zoom-40 .Finance-cash-text-size {
    font-size: 7pt;
}
.zoom-60 .Finance-cash-text-size {
    font-size: 8pt;
}
.zoom-80 .Finance-cash-text-size {
    font-size: 9pt;
}
.zoom-100 .Finance-cash-text-size {
    font-size: 10pt;
}

.zoom-20 .Finance-capital-text,
.zoom-20 .Finance-text-italic {
    font-size: 5pt;
}
.zoom-40 .Finance-capital-text,
.zoom-40 .Finance-text-italic {
    font-size: 6pt;
}
.zoom-60 .Finance-capital-text,
.zoom-60 .Finance-text-italic {
    font-size: 7pt;
}
.zoom-80 .Finance-capital-text,
.zoom-80 .Finance-text-italic {
    font-size: 8pt;
}
.zoom-100 .Finance-capital-text,
.zoom-100 .Finance-text-italic {
    font-size: 9pt;
}


.zoom-20 .Coins-droppable .coin {
    width: 7px!important;
    height: 36px!important;
}
.zoom-40 .Coins-droppable .coin {
    width: 8px!important;
    height: 42px!important;
}
.zoom-60 .Coins-droppable .coin {
    width: 10px!important;
    height: 48px!important;
}
.zoom-80 .Coins-droppable .coin {
    width: 11px!important;
    height: 54px!important;
}
.zoom-100 .Coins-droppable .coin {
    width: 12px!important;
    height: 60px!important;
}


.zoom-20 .Coins-vertical-droppable .coins {
    width: 36px!important;
    height: 10.8px!important;
    left: 6px;
}
.zoom-40 .Coins-vertical-droppable .coins {
    width: 42px!important;
    height: 12.6px!important;
    left: 7px;
}
.zoom-60 .Coins-vertical-droppable .coins {
    width: 48px!important;
    height: 14.4px!important;
    left: 8px;
}
.zoom-80 .Coins-vertical-droppable .coins {
    width: 54px!important;
    height: 16.2px!important;
    left: 9px;
}
.zoom-100 .Coins-vertical-droppable .coins {
    width: 60px!important;
    height: 18px!important;
    left: 10px;
}


.zoom-20 .Token-droppable-field .Token-text {
    font-size: 6px !important;
}
.zoom-40 .Token-droppable-field .Token-text {
    font-size: 7px !important;
}
.zoom-60 .Token-droppable-field .Token-text {
    font-size: 8px !important;
}
.zoom-80 .Token-droppable-field .Token-text {
    font-size: 11px !important;
}
.zoom-100 .Token-droppable-field .Token-text {
    font-size: 13px !important;
}


.zoom-20 .Token-droppable-field .Token-value {
    font-size: 20px;
}
.zoom-40 .Token-droppable-field .Token-value {
    font-size: 24px;
}
.zoom-60 .Token-droppable-field .Token-value {
    font-size: 26px;
}
.zoom-80 .Token-droppable-field .Token-value {
    font-size: 31px;
}
.zoom-100 .Token-droppable-field .Token-value {
    font-size: 35px;
}
/******************************************/


/************* Purchase Block **************/

.zoom-20 .Research-row,
.zoom-20 .Purchase-row {
    height: 58px;
}
.zoom-40 .Research-row,
.zoom-40 .Purchase-row  {
    height: 66px;
}
.zoom-60 .Research-row,
.zoom-60 .Purchase-row  {
    height: 74px;
}
.zoom-80 .Research-row,
.zoom-80 .Purchase-row  {
    height: 83px;
}
.zoom-100 .Research-row,
.zoom-100 .Purchase-row  {
    height: 91px;
}

.zoom-20 .Research-title-text-size,
.zoom-20 .Purchase-title-text-size {
    font-size: 10pt;
}
.zoom-40 .Research-title-text-size,
.zoom-40 .Purchase-title-text-size {
    font-size: 11pt;
}
.zoom-60 .Research-title-text-size,
.zoom-60 .Purchase-title-text-size {
    font-size: 12pt;
}
.zoom-80 .Research-title-text-size,
.zoom-80 .Purchase-title-text-size {
    font-size: 13pt;
}
.zoom-100 .Research-title-text-size,
.zoom-100 .Purchase-title-text-size {
    font-size: 14pt;
}

.zoom-20 .Research-row {
    font-size: 8pt;
}
.zoom-40 .Research-row {
    font-size: 9pt;
}
.zoom-60 .Research-row {
    font-size: 10pt;
}
.zoom-80 .Research-row {
    font-size: 11pt;
}
.zoom-100 .Research-row {
    font-size: 12pt;
}

.zoom-20 .Number-box {
    font-size: 10pt;
    width: 24px;
    height: 24px;
}
.zoom-40 .Number-box {
    font-size: 12pt;
    width: 28px;
    height: 28px;
}
.zoom-60 .Number-box {
    font-size: 14pt;
    width: 32px;
    height: 32px;
}
.zoom-80 .Number-box {
    font-size: 16pt;
    width: 36px;
    height: 36px;
}
.zoom-100 .Number-box {
    font-size: 18pt;
    width: 40px;
    height: 40px;
}

.zoom-20 .Production-Number-box {
    font-size: 10pt;
    width: 24px;
    height: 24px;
}
.zoom-40 .Production-Number-box {
    font-size: 12pt;
    width: 28px;
    height: 28px;
}
.zoom-60 .Production-Number-box {
    font-size: 14pt;
    width: 32px;
    height: 32px;
}
.zoom-80 .Production-Number-box {
    font-size: 16pt;
    width: 36px;
    height: 36px;
}
.zoom-100 .Production-Number-box {
    font-size: 18pt;
    width: 40px;
    height: 40px;
}

/******************************************/

/************ PRODUCTION Block ************/
.zoom-20 .Block-title-text-size {
    font-size: 11pt;
}
.zoom-40 .Block-title-text-size {
    font-size: 12pt;
}
.zoom-60 .Block-title-text-size {
    font-size: 13pt;
}
.zoom-80 .Block-title-text-size {
    font-size: 14pt;
}
.zoom-100 .Block-title-text-size {
    font-size: 15pt;
}

.zoom-20 .Production-title-text-size {
    font-size: 4pt;
}
.zoom-40 .Production-title-text-size {
    font-size: 5pt;
}
.zoom-60 .Production-title-text-size {
    font-size: 6pt;
}
.zoom-80 .Production-title-text-size {
    font-size: 7pt;
}
.zoom-100 .Production-title-text-size {
    font-size: 8pt;
}

.zoom-20 .custom-height {
    height: 14.4px!important;
}
.zoom-40 .custom-height {
    height: 16.8px!important;
}
.zoom-60 .custom-height {
    height: 19.2px!important;
}
.zoom-80 .custom-height {
     height: 21.6px!important;
}
.zoom-100 .custom-height {
    height: 24px!important;
}

.zoom-20 .custom-row-line,
.zoom-20 .custom-height-17,
.zoom-20 .speed-row-text {
    height: 24px!important;
}
.zoom-40 .custom-row-line,
.zoom-40 .custom-height-17,
.zoom-40 .speed-row-text {
    height: 28px!important;
}
.zoom-60 .custom-row-line,
.zoom-60 .custom-height-17,
.zoom-60 .speed-row-text {
    height: 32px!important;
}
.zoom-80 .custom-row-line,
.zoom-80 .custom-height-17,
.zoom-80 .speed-row-text {
    height: 36px!important;
}
.zoom-100 .custom-row-line,
.zoom-100 .custom-height-17,
.zoom-100 .speed-row-text {
    height: 40px!important;
}

.zoom-20 .production-block-title-box {
    height: 36px;
}
.zoom-40 .production-block-title-box {
    height: 42px;
}
.zoom-60 .production-block-title-box {
    height: 48px;
}
.zoom-80 .production-block-title-box {
    height: 54px;
}
.zoom-100 .production-block-title-box {
    height: 60px;
}

.zoom-20 .production-block-title-box-text {
    font-size: 6pt;
}
.zoom-40 .production-block-title-box-text {
    font-size: 7pt;
}
.zoom-60 .production-block-title-box-text {
    font-size: 8pt;
}
.zoom-80 .production-block-title-box-text {
    font-size: 9pt;
}
.zoom-100 .production-block-title-box-text {
    font-size: 10pt;
}

.zoom-20 .Production-sub-title-text-size {
    font-size: 3pt;
}
.zoom-40 .Production-sub-title-text-size {
    font-size: 4pt;
}
.zoom-60 .Production-sub-title-text-size {
    font-size: 5pt;
}
.zoom-80 .Production-sub-title-text-size {
    font-size: 6pt;
}
.zoom-100 .Production-sub-title-text-size {
    font-size: 7pt;
}

.zoom-20 .speed-text {
    font-size: 6pt;
}
.zoom-40 .speed-text {
    font-size: 7pt;
}
.zoom-60 .speed-text {
    font-size: 8pt;
}
.zoom-80 .speed-text {
    font-size: 9pt;
}
.zoom-100 .speed-text {
    font-size: 10pt;
}

.zoom-20 .standard.height,
.zoom-20 .compact.height,
.zoom-20 .plus.height,
.zoom-20 .luxus.height {
    height: 36px!important;
}
.zoom-40 .standard.height,
.zoom-40 .compact.height,
.zoom-40 .plus.height,
.zoom-40 .luxus.height {
    height: 42px!important;
}
.zoom-60 .standard.height,
.zoom-60 .compact.height,
.zoom-60 .plus.height,
.zoom-60 .luxus.height {
    height: 48px!important;
}
.zoom-80 .standard.height,
.zoom-80 .compact.height,
.zoom-80 .plus.height,
.zoom-80 .luxus.height {
    height: 54px!important;
}
.zoom-100 .standard.height,
.zoom-100 .compact.height,
.zoom-100 .plus.height,
.zoom-100 .luxus.height {
    height: 60px!important;
}

.zoom-20 .Production-block-container .Number-box {
    width: 24px !important;
    height: 24px !important;
}
.zoom-40 .Production-block-container .Number-box {
    width: 28px !important;
    height: 28px !important;
}
.zoom-60 .Production-block-container .Number-box {
    width: 32px !important;
    height: 32px !important;
}
.zoom-80 .Production-block-container .Number-box {
    width: 36px !important;
    height: 36px !important;
}
.zoom-100 .Production-block-container .Number-box {
    width: 40px !important;
    height: 40px !important;
}

.zoom-20 .Production-droppable-field {
    height: 96px;
}
.zoom-40 .Production-droppable-field {
    height: 112px;
}
.zoom-60 .Production-droppable-field {
    height: 128px;
}
.zoom-80 .Production-droppable-field {
    height: 144px;
}
.zoom-100 .Production-droppable-field {
    height: 160px;
}

.zoom-20 .vertical-text {
    font-size: 4pt;
}
.zoom-40 .vertical-text {
    font-size: 4.5pt;
}
.zoom-60 .vertical-text {
    font-size: 5pt;
}
.zoom-80 .vertical-text {
    font-size: 5.5pt;
}
.zoom-100 .vertical-text {
    font-size: 6pt;
}

.zoom-20 .vertical-text.second {
    left: 8px;
}
.zoom-40 .vertical-text.second {
    left: 9px;
}
.zoom-60 .vertical-text.second {
    left: 10px;
}
.zoom-80 .vertical-text.second {
    left: 11px;
}
.zoom-100 .vertical-text.second {
    left: 12px;
}
.zoom-20 .vertical-text.third {
    left: 16px;
}
.zoom-40 .vertical-text.third {
    left: 17px;
}
.zoom-60 .vertical-text.third {
    left: 19px;
}
.zoom-80 .vertical-text.third {
    left: 21px;
}
.zoom-100 .vertical-text.third {
    left: 24px;
}
/******************************************/

/*************** Sales Block **************/
.zoom-20 .Sales-title {
    font-size: 9pt;
}
.zoom-40 .Sales-title {
    font-size: 10pt;
}
.zoom-60 .Sales-title {
    font-size: 11pt;
}
.zoom-80 .Sales-title {
    font-size: 12pt;
}
.zoom-100 .Sales-title {
    font-size: 13pt;
}

.zoom-20 .sales-row-title {
    font-size: 7pt;
}
.zoom-40 .sales-row-title {
    font-size: 8pt;
}
.zoom-60 .sales-row-title {
    font-size: 9pt;
}
.zoom-80 .sales-row-title {
    font-size: 10pt;
}
.zoom-100 .sales-row-title {
    font-size: 11pt;
}

.zoom-20 .Colored-droppable-field,
.zoom-20 .sales-row-container {
    height: 48px!important;
}
.zoom-40 .Colored-droppable-field,
.zoom-40 .sales-row-container {
    height: 56px!important;
}
.zoom-60 .Colored-droppable-field,
.zoom-60 .sales-row-container {
    height: 64px!important;
}
.zoom-80 .Colored-droppable-field,
.zoom-80 .sales-row-container {
    height: 72px!important;
}
.zoom-100 .Colored-droppable-field,
.zoom-100 .sales-row-container {
    height: 80px!important;
}

.zoom-20 .Sales-number-box {
    width: 24px;
    height: 33.6px;
    font-size: 11pt;
}
.zoom-40 .Sales-number-box {
    width: 28px;
    height: 39px;
    font-size: 12pt;
}
.zoom-60 .Sales-number-box {
    width: 32px;
    height: 44px;
    font-size: 13pt;
}
.zoom-80 .Sales-number-box {
    width: 36px;
    height: 50px;
    font-size: 14pt;
}
.zoom-100 .Sales-number-box {
    width: 40px;
    height: 54px;
    font-size: 15pt;
}

.zoom-20 .Sales-number-box .Sales-number-height {
    height: 24px;
}
.zoom-40 .Sales-number-box .Sales-number-height {
    height: 28px;
}
.zoom-60 .Sales-number-box .Sales-number-height {
    height: 32px;
}
.zoom-80 .Sales-number-box .Sales-number-height {
    height: 36px;
}
.zoom-100 .Sales-number-box .Sales-number-height {
    height: 40px;
}


.zoom-20 .Sales-number-box .Sales-dot-container {
    height: 10px;
}
.zoom-40 .Sales-number-box .Sales-dot-container {
    height: 11px;
}
.zoom-60 .Sales-number-box .Sales-dot-container {
    height: 12px;
}
.zoom-80 .Sales-number-box .Sales-dot-container {
    height: 13px;
}
.zoom-100 .Sales-number-box .Sales-dot-container {
    height: 14px;
}
/******************************************/


/*************** Bottom Block **************/
.zoom-20 .Bottom-droppable-field {
    height: 48px!important;
}
.zoom-40 .Bottom-droppable-field {
    height: 56px!important;
}
.zoom-60 .Bottom-droppable-field {
    height: 64px!important;
}
.zoom-80 .Bottom-droppable-field {
    height: 72px!important;
}
.zoom-100 .Bottom-droppable-field {
    height: 80px!important;
}

.zoom-20 .Bottom-block .Text-size {
    font-size: 4.6pt;
}
.zoom-40 .Bottom-block .Text-size {
    font-size: 5.6pt;
}
.zoom-60 .Bottom-block .Text-size {
    font-size: 6.6pt;
}
.zoom-80 .Bottom-block .Text-size {
    font-size: 7.6pt;
}
.zoom-100 .Bottom-block .Text-size {
    font-size: 8.6pt;
}

/********************************************/

/*************** Coins ******************/
.zoom-20 .Coins-droppable .coins {
    width: 40px !important;
    height: 18px !important;
}
.zoom-20 .Coins-droppable .coins:nth-child(1) {
    margin-left: -7px;
}
.zoom-20 .Coins-droppable .coins:nth-child(2) {
    left: 0px;
}
.zoom-20 .Coins-droppable .coins:nth-child(3) {
    left: 4px;
}
.zoom-20 .Coins-droppable .coins:nth-child(4) {
    left: 8px;
}
.zoom-20 .Coins-droppable .coins:nth-child(5) {
    left: 12px;
}
.zoom-20 .Coins-droppable .coins:nth-child(6) {
    left: 16px;
}
.zoom-20 .Coins-droppable .coins:nth-child(7) {
    left: 19px;
}
.zoom-20 .Coins-droppable .coins:nth-child(8) {
    left: 23px;
}
.zoom-20 .Coins-droppable .coins:nth-child(9) {
    left: 27px;
}
.zoom-20 .Coins-droppable .coins:nth-child(10) {
    left: 31px;
}
.zoom-20 .Coins-droppable .coins:nth-child(11) {
    left: 35px;
}
.zoom-20 .Coins-droppable .coins:nth-child(12) {
    left: 38px;
}
.zoom-20 .Coins-droppable .coins:nth-child(13) {
    left: 42px;
}
.zoom-20 .Coins-droppable .coins:nth-child(14) {
    left: 46px;
}
.zoom-20 .Coins-droppable .coins:nth-child(15) {
    left: 50px;
}
.zoom-20 .Coins-droppable .coins:nth-child(16) {
    left: 54px;
}
.zoom-20 .Coins-droppable .coins:nth-child(17) {
    left: 57px;
}
.zoom-20 .Coins-droppable .coins:nth-child(18) {
    left: 61px;
}
.zoom-20 .Coins-droppable .coins:nth-child(19) {
    left: 65px;
}
.zoom-20 .Coins-droppable .coins:nth-child(20) {
    left: 69px;
}

.zoom-40 .Coins-droppable .coins:nth-child(1) {
    left: -3px;
}
.zoom-40 .Coins-droppable .coins:nth-child(2) {
    left: 1px;
}
.zoom-40 .Coins-droppable .coins:nth-child(3) {
    left: 5px;
}
.zoom-40 .Coins-droppable .coins:nth-child(4) {
    left: 9px;
}
.zoom-40 .Coins-droppable .coins:nth-child(5) {
    left: 13px;
}
.zoom-40 .Coins-droppable .coins:nth-child(6) {
    left: 19px;
}
.zoom-40 .Coins-droppable .coins:nth-child(7) {
    left: 23px;
}
.zoom-40 .Coins-droppable .coins:nth-child(8) {
    left: 27px;
}
.zoom-40 .Coins-droppable .coins:nth-child(9) {
    left: 31px;
}
.zoom-40 .Coins-droppable .coins:nth-child(10) {
    left: 35px;
}
.zoom-40 .Coins-droppable .coins:nth-child(11) {
    left: 41px;
}
.zoom-40 .Coins-droppable .coins:nth-child(12) {
    left: 45px;
}
.zoom-40 .Coins-droppable .coins:nth-child(13) {
    left: 49px;
}
.zoom-40 .Coins-droppable .coins:nth-child(14) {
    left: 53px;
}
.zoom-40 .Coins-droppable .coins:nth-child(15) {
    left: 57px;
}
.zoom-40 .Coins-droppable .coins:nth-child(16) {
    left: 63px;
}
.zoom-40 .Coins-droppable .coins:nth-child(17) {
    left: 67px;
}
.zoom-40 .Coins-droppable .coins:nth-child(18) {
    left: 71px;
}
.zoom-40 .Coins-droppable .coins:nth-child(19) {
    left: 75px;
}
.zoom-40 .Coins-droppable .coins:nth-child(20) {
    left: 79px;
}

.zoom-60 .Coins-droppable .coins:nth-child(1) {
    left: -3px;
}
.zoom-60 .Coins-droppable .coins:nth-child(2) {
    left: 2px;
}
.zoom-60 .Coins-droppable .coins:nth-child(3) {
    left: 7px;
}
.zoom-60 .Coins-droppable .coins:nth-child(4) {
    left: 12px;
}
.zoom-60 .Coins-droppable .coins:nth-child(5) {
    left: 17px;
}
.zoom-60 .Coins-droppable .coins:nth-child(6) {
    left: 24px;
}
.zoom-60 .Coins-droppable .coins:nth-child(7) {
    left: 29px;
}
.zoom-60 .Coins-droppable .coins:nth-child(8) {
    left: 34px;
}
.zoom-60 .Coins-droppable .coins:nth-child(9) {
    left: 39px;
}
.zoom-60 .Coins-droppable .coins:nth-child(10) {
    left: 44px;
}
.zoom-60 .Coins-droppable .coins:nth-child(11) {
    left: 51px;
}
.zoom-60 .Coins-droppable .coins:nth-child(12) {
    left: 56px;
}
.zoom-60 .Coins-droppable .coins:nth-child(13) {
    left: 61px;
}
.zoom-60 .Coins-droppable .coins:nth-child(14) {
    left: 66px;
}
.zoom-60 .Coins-droppable .coins:nth-child(15) {
    left: 71px;
}
.zoom-60 .Coins-droppable .coins:nth-child(16) {
    left: 78px;
}
.zoom-60 .Coins-droppable .coins:nth-child(17) {
    left: 83px;
}
.zoom-60 .Coins-droppable .coins:nth-child(18) {
    left: 88px;
}
.zoom-60 .Coins-droppable .coins:nth-child(19) {
    left: 93px;
}
.zoom-60 .Coins-droppable .coins:nth-child(20) {
    left: 98px;
}


.zoom-80 .Coins-droppable .coins:nth-child(1) {
    left: -3px;
}
.zoom-80 .Coins-droppable .coins:nth-child(2) {
    left: 2px;
}
.zoom-80 .Coins-droppable .coins:nth-child(3) {
    left: 7px;
}
.zoom-80 .Coins-droppable .coins:nth-child(4) {
    left: 12px;
}
.zoom-80 .Coins-droppable .coins:nth-child(5) {
    left: 17px;
}
.zoom-80 .Coins-droppable .coins:nth-child(6) {
    left: 26px;
}
.zoom-80 .Coins-droppable .coins:nth-child(7) {
    left: 31px;
}
.zoom-80 .Coins-droppable .coins:nth-child(8) {
    left: 36px;
}
.zoom-80 .Coins-droppable .coins:nth-child(9) {
    left: 41px;
}
.zoom-80 .Coins-droppable .coins:nth-child(10) {
    left: 46px;
}
.zoom-80 .Coins-droppable .coins:nth-child(11) {
    left: 55px;
}
.zoom-80 .Coins-droppable .coins:nth-child(12) {
    left: 60px;
}
.zoom-80 .Coins-droppable .coins:nth-child(13) {
    left: 65px;
}
.zoom-80 .Coins-droppable .coins:nth-child(14) {
    left: 70px;
}
.zoom-80 .Coins-droppable .coins:nth-child(15) {
    left: 75px;
}
.zoom-80 .Coins-droppable .coins:nth-child(16) {
    left: 84px;
}
.zoom-80 .Coins-droppable .coins:nth-child(17) {
    left: 89px;
}
.zoom-80 .Coins-droppable .coins:nth-child(18) {
    left: 94px;
}
.zoom-80 .Coins-droppable .coins:nth-child(19) {
    left: 99px;
}
.zoom-80 .Coins-droppable .coins:nth-child(20) {
    left: 104px;
}


.zoom-100 .Coins-droppable .coins:nth-child(1) {
    left: -3px;
}
.zoom-100 .Coins-droppable .coins:nth-child(2) {
    left: 3px;
}
.zoom-100 .Coins-droppable .coins:nth-child(3) {
    left: 9px;
}
.zoom-100 .Coins-droppable .coins:nth-child(4) {
    left: 15px;
}
.zoom-100 .Coins-droppable .coins:nth-child(5) {
    left: 21px;
}
.zoom-100 .Coins-droppable .coins:nth-child(6) {
    left: 31px;
}
.zoom-100 .Coins-droppable .coins:nth-child(7) {
    left: 37px;
}
.zoom-100 .Coins-droppable .coins:nth-child(8) {
    left: 43px;
}
.zoom-100 .Coins-droppable .coins:nth-child(9) {
    left: 49px;
}
.zoom-100 .Coins-droppable .coins:nth-child(10) {
    left: 55px;
}
.zoom-100 .Coins-droppable .coins:nth-child(11) {
    left: 65px;
}
.zoom-100 .Coins-droppable .coins:nth-child(12) {
    left: 71px;
}
.zoom-100 .Coins-droppable .coins:nth-child(13) {
    left: 77px;
}
.zoom-100 .Coins-droppable .coins:nth-child(14) {
    left: 83px;
}
.zoom-100 .Coins-droppable .coins:nth-child(15) {
    left: 89px;
}
.zoom-100 .Coins-droppable .coins:nth-child(16) {
    left: 99px;
}
.zoom-100 .Coins-droppable .coins:nth-child(17) {
    left: 105px;
}
.zoom-100 .Coins-droppable .coins:nth-child(18) {
    left: 111px;
}
.zoom-100 .Coins-droppable .coins:nth-child(19) {
    left: 117px;
}
.zoom-100 .Coins-droppable .coins:nth-child(20) {
    left: 123px;
}



.zoom-20 .Coins-vertical-droppable .coin {
    width: 36px !important;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(1) {
    margin-top: -1px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(2) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(3) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(4) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(5) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(6) {
    margin-top: -1px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(7) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(8) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(9) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(10) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(11) {
    margin-top: -1px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(12) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(13) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(14) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(15) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(16) {
    margin-top: -1px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(17) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(18) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(19) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin:nth-child(20) {
    margin-top: -6px;
}
.zoom-20 .Coins-vertical-droppable .coin.last-coin{
    height: 8px!important;
}

.zoom-40 .Coins-vertical-droppable .coin {
    width: 42px !important;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(1) {
    margin-top: 0px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(2) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(3) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(4) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(5) {
    margin-top: -6px;
    height: 11px !important;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(6) {
    margin-top: 0px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(7) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(8) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(9) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(10) {
    margin-top: -6px;
    height: 11px !important;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(11) {
    margin-top: 0px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(12) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(13) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(14) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(15) {
    margin-top: -6px;
    height: 11px !important;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(16) {
    margin-top: 1px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(17) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(18) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(19) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin:nth-child(20) {
    margin-top: -6px;
}
.zoom-40 .Coins-vertical-droppable .coin.last-coin{
    height: 11px!important;
}

.zoom-60 .Coins-vertical-droppable .coin {
    width: 48px !important;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(1) {
    margin-top: -1px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(2) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(3) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(4) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(5) {
    margin-top: -5px;
    height: 11px !important;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(6) {
    margin-top: -1px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(7) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(8) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(9) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(10) {
    margin-top: -5px;
    height: 12px !important;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(11) {
    margin-top: -1px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(12) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(13) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(14) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(15) {
    margin-top: -5px;
    height: 12px !important;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(16) {
    margin-top: -1px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(17) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(18) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(19) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin:nth-child(20) {
    margin-top: -5px;
}
.zoom-60 .Coins-vertical-droppable .coin.last-coin{
    height: 12px!important;
}

.zoom-80 .Coins-vertical-droppable .coin {
    width: 54px !important;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(1) {
    margin-top: 0px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(2) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(3) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(4) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(5) {
    margin-top: -5px;
    height: 13px !important;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(6) {
    margin-top: 0px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(7) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(8) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(9) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(10) {
    margin-top: -5px;
    height: 14px !important;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(11) {
    margin-top: 0px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(12) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(13) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(14) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(15) {
    margin-top: -5px;
    height: 13px!important;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(16) {
    margin-top: 0px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(17) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(18) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(19) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin:nth-child(20) {
    margin-top: -5px;
}
.zoom-80 .Coins-vertical-droppable .coin.last-coin{
    height: 15px!important;
}


.zoom-100 .Coins-vertical-droppable .coin {
    width: 60px !important;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(1) {
    margin-top: -1px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(2) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(3) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(4) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(5) {
    margin-top: -4px;
    height: 13px !important;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(6) {
    margin-top: 0px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(7) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(8) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(9) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(10) {
    margin-top: -4px;
    height: 13px !important;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(11) {
    margin-top: 0px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(12) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(13) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(14) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(15) {
    margin-top: -4px;
    height: 13px !important;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(16) {
    margin-top: 1px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(17) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(18) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(19) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin:nth-child(20) {
    margin-top: -4px;
}
.zoom-100 .Coins-vertical-droppable .coin.last-coin{
    height: 14px!important;
}


.zoom-20 .Coins-vertical-droppable .Container-Card {
    width: 36px !important;
    height: 36px !important;
    left: 50%;
    margin-left: -18px;
    bottom: -9px !important;
}
.zoom-40 .Coins-vertical-droppable .Container-Card {
    width: 42px !important;
    height: 42px !important;
    left: 50%;
    margin-left: -21px;
    bottom: -10px !important;
}
.zoom-60 .Coins-vertical-droppable .Container-Card {
    width: 48px !important;
    height: 48px !important;
    left: 50%;
    margin-left: -24px;
    bottom: -11px !important;
}
.zoom-80 .Coins-vertical-droppable .Container-Card {
    width: 54px !important;
    height: 54px !important;
    left: 50%;
    margin-left: -27px;
    bottom: -12px !important;
}
.zoom-100 .Coins-vertical-droppable .Container-Card {
    width: 60px !important;
    height: 60px !important;
    left: 50%;
    margin-left: -30px;
    bottom: -13px !important;
}


.zoom-20 .Coins-vertical-droppable .containerProductCardDraggable {
    width: 36px !important;
    height: 36px !important;
    left: 50%;
    margin-left: -18px;
    bottom: -9px !important;
}
.zoom-40 .Coins-vertical-droppable .containerProductCardDraggable {
    width: 42px !important;
    height: 42px !important;
    left: 50%;
    margin-left: -21px;
    bottom: -10px !important;
}
.zoom-60 .Coins-vertical-droppable .containerProductCardDraggable {
    width: 48px !important;
    height: 48px !important;
    left: 50%;
    margin-left: -24px;
    bottom: -12px !important;
}
.zoom-80 .Coins-vertical-droppable .containerProductCardDraggable {
    width: 54px !important;
    height: 54px !important;
    left: 50%;
    margin-left: -27px;
    bottom: -14px !important;
}
.zoom-100 .Coins-vertical-droppable .containerProductCardDraggable {
    width: 60px !important;
    height: 60px !important;
    left: 50%;
    margin-left: -30px;
    bottom: -15px !important;
}

.zoom-20 .containerVerticalProductCardDraggable {
    width: 36px!important;
    height: 36px!important;
    right: -9px;
    top: 50%;
    margin-top: -18px;
}
.zoom-40 .containerVerticalProductCardDraggable {
    width: 42px!important;
    height: 42px!important;
    right: -10px;
    top: 50%;
    margin-top: -21px;
}
.zoom-60 .containerVerticalProductCardDraggable {
    width: 48px!important;
    height: 48px!important;
    right: -11px;
    top: 50%;
    margin-top: -24px;
}
.zoom-80 .containerVerticalProductCardDraggable {
    width: 54px!important;
    height: 54px!important;
    right: -12px;
    top: 50%;
    margin-top: -27px;
}
.zoom-100 .containerVerticalProductCardDraggable {
    width: 60px!important;
    height: 60px!important;
    right: -13px;
    top: 50%;
    margin-top: -30px;
}



.zoom-20 .Coins-droppable .coin:nth-child(2) {
    margin-left: -1px;
}
.zoom-20 .Coins-droppable .coin:nth-child(3) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(4) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(5) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(6) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(7) {
    margin-left: 0px;
}
.zoom-20 .Coins-droppable .coin:nth-child(8) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(9) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(10) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(11) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(12) {
    margin-left: 1px;
}
.zoom-20 .Coins-droppable .coin:nth-child(13) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(14) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(15) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(16) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(17) {
    margin-left: 0px;
}
.zoom-20 .Coins-droppable .coin:nth-child(18) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(19) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(20) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin:nth-child(21) {
    margin-left: -4px;
}
.zoom-20 .Coins-droppable .coin.last-coin{
    width: 8px!important;
}


.zoom-40 .Coins-droppable .coin:nth-child(2) {
    margin-left: -1px;
}
.zoom-40 .Coins-droppable .coin:nth-child(3) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(4) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(5) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(6) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(7) {
    margin-left: -1px;
}
.zoom-40 .Coins-droppable .coin:nth-child(8) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(9) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(10) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(11) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(12) {
    margin-left: -1px;
}
.zoom-40 .Coins-droppable .coin:nth-child(13) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(14) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(15) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(16) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(17) {
    margin-left: -1px;
}
.zoom-40 .Coins-droppable .coin:nth-child(18) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(19) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(20) {
    margin-left: -4px;
}
.zoom-40 .Coins-droppable .coin:nth-child(21) {
    margin-left: -4px;
}


.zoom-60 .Coins-droppable .coin:nth-child(2) {
    margin-left: -1px;
}
.zoom-60 .Coins-droppable .coin:nth-child(3) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(4) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(5) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(6) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(7) {
    margin-left: 1px;
}
.zoom-60 .Coins-droppable .coin:nth-child(8) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(9) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(10) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(11) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(12) {
    margin-left: 1px;
}
.zoom-60 .Coins-droppable .coin:nth-child(13) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(14) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(15) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(16) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(17) {
    margin-left: 1px;
}
.zoom-60 .Coins-droppable .coin:nth-child(18) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(19) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(20) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin:nth-child(21) {
    margin-left: -6px;
}
.zoom-60 .Coins-droppable .coin.last-coin{
    width: 12px!important;
}


.zoom-80 .Coins-droppable .coin:nth-child(2) {
    margin-left: -1px;
}
.zoom-80 .Coins-droppable .coin:nth-child(3) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(4) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(5) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(6) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(7) {
    margin-left: -1px;
}
.zoom-80 .Coins-droppable .coin:nth-child(8) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(9) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(10) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(11) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(12) {
    margin-left: -1px;
}
.zoom-80 .Coins-droppable .coin:nth-child(13) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(14) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(15) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(16) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(17) {
    margin-left: 0px;
}
.zoom-80 .Coins-droppable .coin:nth-child(18) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(19) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(20) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin:nth-child(21) {
    margin-left: -6px;
}
.zoom-80 .Coins-droppable .coin.last-coin{
    width: 13px!important;
}



.zoom-100 .Coins-droppable .coin:nth-child(2) {
    margin-left: -2px;
}
.zoom-100 .Coins-droppable .coin:nth-child(3) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(4) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(5) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(6) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(7) {
    margin-left: -2px;
}
.zoom-100 .Coins-droppable .coin:nth-child(8) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(9) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(10) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(11) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(12) {
    margin-left: -2px;
}
.zoom-100 .Coins-droppable .coin:nth-child(13) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(14) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(15) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(16) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(17) {
    margin-left: -2px;
}
.zoom-100 .Coins-droppable .coin:nth-child(18) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(19) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(20) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin:nth-child(21) {
    margin-left: -6px;
}
.zoom-100 .Coins-droppable .coin.last-coin{
    width: 14px!important;
}


.zoom-20 .Coins-vertical-droppable .Container-Card-2 {
    width: 36px !important;
    height: 36px !important;
    margin-left: -18px !important;
}
.zoom-40 .Coins-vertical-droppable .Container-Card-2 {
    width: 42px !important;
    height: 42px !important;
    margin-left: -21px !important;
}
.zoom-60 .Coins-vertical-droppable .Container-Card-2 {
    width: 48px !important;
    height: 48px !important;
    margin-left: -24px !important;
}
.zoom-80 .Coins-vertical-droppable .Container-Card-2 {
    width: 54px !important;
    height: 54px !important;
    margin-left: -27px !important;
}
.zoom-100 .Coins-vertical-droppable .Container-Card-2 {
    width: 60px !important;
    height: 60px !important;
    margin-left: -30px !important;
}


.zoom-20 .field-content-title,
.zoom-20 .field-content-value {
    font-size: 9px;
}
.zoom-40 .field-content-title,
.zoom-40 .field-content-value {
    font-size: 10px;
}
.zoom-60 .field-content-title,
.zoom-60 .field-content-value {
    font-size: 11px;
}
.zoom-80 .field-content-title,
.zoom-80 .field-content-value {
    font-size: 12px;
}
.zoom-100 .field-content-title,
.zoom-100 .field-content-value {
    font-size: 13px;
}


.zoom-20 .field-content-image-row {
    height: 51px;
}
.zoom-40 .field-content-image-row {
    height: 62px;
}
.zoom-60 .field-content-image-row {
    height: 74px;
}
.zoom-80 .field-content-image-row {
    height: 84px;
}
.zoom-100 .field-content-image-row {
    height: 98px;
}


.zoom-20 .field-content-image-row.machine img {
    width: 24px;
}
.zoom-40 .field-content-image-row.machine img {
    width: 28px;
}
.zoom-60 .field-content-image-row.machine img {
    width: 32px;
}
.zoom-80 .field-content-image-row.machine img {
    width: 36px;
}
.zoom-100 .field-content-image-row.machine img {
    width: 40px;
}

.zoom-20 .field-content-image-row.product img {
    width: 34px;
}
.zoom-40 .field-content-image-row.product img {
    width: 40px;
}
.zoom-60 .field-content-image-row.product img {
    width: 45px;
}
.zoom-80 .field-content-image-row.product img {
    width: 50px;
}
.zoom-100 .field-content-image-row.product img {
    width: 56px;
}