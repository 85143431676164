.Finance-container {
    background-color: #BFC5D0;
    border: 1px solid #000;
    width: 100%;
    text-align: left;
    padding: 0px 5px 5px 5px !important;
}

.Finance-bold-title-text-size {
    font-size: 10pt;
    font-weight: bolder;
}

.Finance-title-text-size {
    font-size: 10pt;
}

.Finance-droppable-container {
    background-color: #DCDDDE;
    padding: 2px;
}

.Finance-droppable-field {
    background-color: #FFFFFF;
    width: 100%;
    height: 40px;
}

.Finance-droppable-container .col {
    margin: 1px;
}

.Finance-text-size {
    font-size: 6pt;
}
.Finance-cash-text-size {
    font-size: 5pt;
}

.Finance-capital-text {
    font-size: 4pt;
    font-weight: bold;
}

.Finance-text-italic {
    font-size: 4pt;
    font-style: italic;
}

.Finance-capital-field {
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 75%; /* 90% => 1:1 Aspect Ratio */
    position: relative;
}

.Finance-capital-container {
    min-height: 100%;
    width: 20%;
    display: block;
    margin: auto 0;
}
.Finance-cash-container {
    width: 80%;
}

.Liability-block {
    margin-top: 10px;
}

.Liability-right-section {
    text-align: right;
    padding-right: 10px !important;
}

.Token-container-block {
    background-color: #BC1700;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.Token-droppable-field {
    position: relative;
    background-color: #FFFFFF;
    border: 4px solid #BC1700;
    width: 96%;
    height: 48px;
}
.Token-droppable-field.token_black {
    border: 4px solid #000000 !important;
}

.width-20-percent {
    width: 20%;
}
.width-40-percent {
    width: 40%;
}

.Token-container {
    background-color: #BC1700;
    width: 96%;
    height: 96%;
    color: white;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    cursor: default;
}
.Token-container.black {
    background-color: #000000 !important;
}
.Token-container .Token-value {
    height: 60%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}
.Token-container .Token-text {
    height: 40%;
    font-size: 5px;
    text-align: center;
    font-weight: bold;
}
.Token-container.black .Token-value {
    color: #f0dba6;
}
.Token-container.black .Token-text {
    color: #f0dba6;
    font-size: 6px;
}


.capitalInput {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    border: 0px !important;
    font-size: 22px;
    font-weight: bold;
}