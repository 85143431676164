.formContainer {
	background-color: #0A2955;
	padding: 10px;
}
.formContainer .removeLeftPadding {
	padding-left: 0px !important;
}

.formContainer .bold {
	color: #ffffff;
	font-size: 12px;
	font-weight: bold;
}
.formContainer .formHeader {
	display: flex;
	padding: 10px;
}
.formContainer .headerTitle {
	color: #FFFFFF;
	font-size: 18px;
	flex: 2;
}
.formContainer .closeForm {
	color: #FFFFFF;
	cursor: pointer;
	font-size: 18px;
	flex: 1;
	text-align: right;
}
.formContainer td {
	padding: 5px !important;
}
.formContainer .btn {
	font-size: 10px !important;
}
.checkContainer {
	display: flex;
	width: 70px;
	height: 25px;
	border: 1px solid lightgray;
	border-radius: 2px;
	cursor: pointer;
}
.checkContainer svg {
	color: #FFFFFF;
	margin: auto;
}

.hintForm {
	font-size: 10px;
	font-style: italic;
	color: lightgray;
}

.form-link a {
	cursor: pointer;
}
.form-link a:hover {
	text-decoration: underline;
}

.whiteDropdown button {
	color: #FFFFFF;
    border-color: #FFFFFF;
}