.Sales-title {
    font-size: 8pt;
}
.Sales-title.bold-text {
    font-weight: bold;
}

.sales-row-title {
    font-size: 6pt;
    margin: auto 0;
}

.sales-row-container {
    height: 40px;
    background-color: #E7E8E8;
    padding: 4px;
    margin-bottom: 5px;
}

.Sales-number-box {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    font-size: 10pt;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    height: 28px;
    text-align: center;
    margin-right: 2px;
}

.Sales-dot-class {
    width: 2px;
    height: 2px;
    background-color: #000000;
    margin: auto;
    border: 1px solid #000000;
    border-radius: 2px;
}

.Stock-blue-block {
    background-color: #72CEE6;
    border: 1px solid #AFDDE9;
    width: 100%;
    margin-bottom: 5px;
    padding: 2px !important;
}
.Stock-red-block {
    background-color: #C47976;
    border: 1px solid #D5B3B4;
    width: 100%;
    padding: 2px !important;
}

.Stock-green-block {
    background-color: #91BE8C;
    border: 1px solid #C5D9C6;
    width: 100%;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px !important;
}
.Stock-yellow-block {
    background-color: #FCEA95;
    border: 1px solid #EDE7C9;
    width: 100%;
    margin-right: 5px;
    padding: 2px !important;
}

.Stock-red-block .Colored-droppable-field {
    background-color: #901027;
    border: 0px solid #901027;
    height: 40px;
}
.Stock-blue-block .Colored-droppable-field {
    background-color: #2AACD5;
    border: 0px solid #2AACD5;
    height: 40px;
}
.Stock-green-block .Colored-droppable-field {
    background-color: #23923C;
    border: 0px solid #23923C;
    height: 40px;
}
.Stock-yellow-block .Colored-droppable-field {
    background-color: #FBD901;
    border: 0px solid #FBD901;
    height: 40px;
}


.colored-block .col {
    margin: 1px;
}

.sales-row-container .col {
    margin: auto 0;
}

.Sales-number-height {
    height: 20px;
}
.Sales-dot-container {
    position: relative;
    height: 8px;
}

.Sales-dot-container .pinImgView {
    margin-left: -5px !important;
    margin-top: -21px !important;
}